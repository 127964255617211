<template>
  <b-card>
    <form-component :about-us-review="aboutUsReview" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/about-us-reviews/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { show, update } from '@/http/about-us-reviews';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      aboutUsReview: {
        name: '',
        position: '',
        review: '',
        lang: '',
        image: null,
      },
    };
  },

  async created() {
    await this.fetchAboutUsReview();
  },

  methods: {
    async fetchAboutUsReview() {
      try {
        const { data } = await show(this.$route.params.id);

        this.aboutUsReview = data.data;
      } catch (error) {
        this.showErrorNotification(
          'Problem z pobraniem danych',
          'Wystąpił błąd podczas pobierania opinii. Skontaktuj się ze swoim developerem.',
        );
      }
    },

    async onSubmit(form) {
      const formData = new FormData();

      formData.append('id', form.id);
      formData.append('name', form.name);
      formData.append('position', form.position);
      formData.append('review', form.review);
      formData.append('lang', form.lang);
      formData.append('image', form.image.file, form.image.file.fileName);

      try {
        await update(form.id, formData);

        this.showSuccessNotification('Dane zapisane', 'Opinia została pomyślnie zaktualizowana.');
        this.$router.push({ name: 'about-us-reviews-index' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas aktualizacji opinii. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
